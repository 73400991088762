<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="1400px">
      <v-card v-if="!successScreen">
        <v-card-title>
          <div class="container text-right">
            <v-menu
                bottom
                right
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="close-menu"
                  @click="close()"
                >
                  <v-icon class="close-icon">mdi-close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </div>
          <div class="container text-center">
            <div class="circle-gradient-big mb-4">
            </div>
          </div>
          <h4 class="purple-color dont-break message-title">{{ $t('order_card_header') }}</h4>
        </v-card-title>
        <v-card-text class="dont-break">
          <div v-html="content"></div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="12" class="text-center">
            <button class="main-button-purple" :disabled="loading" @click="acceptOffer()">
              {{ $t('accept') }}
              <v-progress-circular
                v-if="loading"
                :size="30"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </button>
          </v-col>
        </v-card-actions>
      </v-card>
      <v-card v-if="successScreen">
        <v-card-title>
          <div class="container text-right">
            <v-menu
                bottom
                right
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="close-menu"
                  @click="close()"
                >
                  <v-icon class="close-icon">mdi-close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </div>
          <div class="container text-center">
            <div class="circle-gradient-big-success mb-4">
            </div>
            <h4 class="dont-break message-title">{{ $t('order_card_header_success') }}</h4>
          </div>
        </v-card-title>
        <v-card-text class="dont-break text-center">
          <div>{{ $t('order_card_header_success_info') }}</div>
          <h2 style="color: #212121;" class="mt-4">{{ $t('stay_in_touch') }}</h2>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="12" class="text-center">
            <router-link :to="'/documents'">
              <button class="main-button main-button-fixed">
                {{ $t('back_to_panel') }}
              </button>
            </router-link>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  name: 'showAcceptOfferModal',
  data () {
    return {
      dialog: true,
      successScreen: false,
      loading: false
    }
  },
  computed: {
    content () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return ''
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return ''
      } else if (location.host.includes('.euro-tax.pl')) {
        return 'Wyrażam zgodę w celu umożliwienia uzyskiwania przez Euro-Tax.pl Zwrot Podatku S.A. informacji od zagranicznych urzędów o uzyskanych dochodach i przedstawienia mi bezpłatnych analiz w ramach propozycji rozliczeń podatku za 2022 rok.'
      } else {
        return 'Wyrażam zgodę w celu umożliwienia uzyskiwania przez CUF sp. z o.o. informacji od zagranicznych urzędów o uzyskanych dochodach i przedstawienia mi bezpłatnych analiz w ramach propozycji rozliczeń podatku za 2022 rok.'
      }
    }
  },
  methods: {
    acceptOffer () {
      this.$http.put(`documents/${this.$route.params.documentId}/accept_order_offer`)
        .then(response => {
          this.successScreen = true
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
  .v-dialog {
    max-width: 700px !important;
  }
  .purple-color {
    color: #d2116e;
    text-decoration: none;
  }

  .circle-gradient-big {
    background: transparent url('../../../assets/icons/accept-header.svg') 0% 0% no-repeat padding-box;
    display: inline-block;
    width: 164px;
    height: 164px;
    background-size: 164px 164px;

    i {
      margin-top: 30%;
      color: white;
      font-size: 64px;
    }
  }
  .circle-gradient-big-success {
    background: transparent url('../../../assets/icons/accept-success-header.svg') 0% 0% no-repeat padding-box;
    display: inline-block;
    width: 164px;
    height: 164px;
    background-size: 164px 164px;

    i {
      margin-top: 30%;
      color: white;
      font-size: 64px;
    }
  }
</style>
