<template>
  <div class="container">
    <div v-if="!documentsLoading">
      <div class="row container">
        <h2 class="pt-2 semibold-font">{{ $t('order_tax_card') }}</h2>
      </div>
      <div class="row container d-none d-lg-flex">
        <h4 class="year_h4">{{document.attributes['year'] }}</h4>
        <h4 class="product_h4 ml-4">{{document.attributes['product'] }}</h4>
      </div>
      <div class="row d-flex d-lg-none mt-0 pt-0 pb-0">
        <v-col cols="9">
          <h4 class="year_h4">
            <span>{{ document.attributes['year'] }}</span>
            <span class="product_h4 ml-4">{{document.attributes['product'] }}</span>
          </h4>
        </v-col>
      </div>
      <div class="row pa-2 pr-2">
        <v-col cols="12" lg="5">
          <div class="document-status pa-6">
            <div class="document-current-step-description mt-8">
              <h4 class="mb-4">{{ $t('order_offer_text_header') }}</h4>
              <p>{{ $t('order_offer_text1') }}</p>
              <p>{{ $t('order_offer_text2', { year: document.attributes['year'] }) }}</p>
              <p>{{ $t('order_offer_text3') }}</p>
            </div>
          </div>

          <div class="purple-radius-border mt-6 pa-6">
            <h4>{{ $t('accept_consent') }}</h4>

            <div class="row mt-3">
              <v-col cols="2">
                <button class="offer-icon ml-2 mr-4"></button>
              </v-col>
              <v-col cols="10">
                <div style="color: #707070">{{ acceptContent }}</div>
                <div class="mt-6">
                  <div class="see-more" @click="displayAcceptOfferModal()">
                    {{ $t('accept') }}
                    <v-icon
                      small
                      class="ml-2 arrow-icon"
                    >
                      fas fa-arrow-right
                    </v-icon>
                  </div>
                </div>
              </v-col>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="6"  class="pa-0 pa-lg-6 ml-0 ml-lg-4">
          <div class="row mt-6 ml-lg-4">
            <v-col cols="12" lg="10" class="mb-4">
              <div class="refund-value">{{ $t('you_can_recover') }}</div>
              <div class="question-marks mt-2">
                <div class="question-mark">
                  <img src="@/assets/icons/question-mark1.png">
                </div>
                <div class="question-mark">
                  <img src="@/assets/icons/question-mark1.png">
                </div>
                <div class="question-mark">
                  <img src="@/assets/icons/question-mark1.png">
                </div>
                <div class="question-mark">
                  <img src="@/assets/icons/question-mark-last1.png">
                </div>
                <div class="euro-icon mr-1"> € </div>
                <div class="question-mark">
                  <span style="color: #707070"> {{ $t('tax_return') }} </span>
                </div>
              </div>
              <hr class="hr-line mt-8 mb-8">
              <div class="document-current-step-description">
                {{ $t('order_info') }}
              </div>
            </v-col>
            <v-col cols="12 text-center text-lg-left">
              <div class="container row mt-6">
                <div class="col-1 mr-lg-2">
                  <div class="extension-square purple-color-square">
                    <span>PDF</span>
                  </div>
                </div>
                <div class="col-10 ml-2 ml-lg-0 pr-0 pr-lg-2">
                  <h3 class="ml-3">{{ $t('here_tax_card') }}</h3>
                  <p class="ml-3" style="color: #707070">
                    {{ $t('here_tax_card_description') }}
                  </p>
                  <div class="see-more ml-2 mb-12 pa-md-0" @click="displayAcceptOfferModal()">
                    {{ $t('order') }}
                    <v-icon
                      small
                      class="ml-2 arrow-icon"
                    >
                      fas fa-arrow-right
                    </v-icon>
                  </div>
                </div>
              </div>
            </v-col>
          </div>
        </v-col>
      </div>
    </div>
    <showAcceptOfferModal v-if="displayShowAcceptOfferModal" @close="displayShowAcceptOfferModal = false"></showAcceptOfferModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import showAcceptOfferModal from '@/components/document/general/showAcceptOfferModal'

export default {
  name: 'orderOffer',
  data () {
    return {
      displayShowAcceptOfferModal: false
    }
  },
  components: {
    showAcceptOfferModal
  },
  computed: {
    ...mapGetters(['document']),
    ...mapGetters(['documentsLoading']),

    acceptContent () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return ''
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return ''
      } else if (location.host.includes('.euro-tax.pl')) {
        return 'Wyrażam zgodę w celu umożliwienia uzyskiwania przez Euro-Tax.pl Zwrot Podatku S.A. informacji od zagranicznych urzędów o uzyskanych dochodach i przedstawienia mi bezpłatnych analiz w ramach propozycji rozliczeń podatku za 2022 rok.'
      } else {
        return 'Wyrażam zgodę w celu umożliwienia uzyskiwania przez CUF sp. z o.o. informacji od zagranicznych urzędów o uzyskanych dochodach i przedstawienia mi bezpłatnych analiz w ramach propozycji rozliczeń podatku za 2022 rok.'
      }
    }
  },
  created () {
    this.$store.dispatch('getDocument', this.$route.params.documentId)
  },
  methods: {
    displayAcceptOfferModal () {
      this.displayShowAcceptOfferModal = true
    }
  }
}
</script>

<style lang="scss">
  .purple-radius-border {
    border: 2px solid #D20F6E;
    border-radius: 10px;

    h4 {
      color: #D20F6E;
      margin-top: -35px;
      background: #f7f7f7;
      text-align: center;
    }

    .offer-icon {
      background: transparent url('../../../assets/icons/agreement.svg') 0% 0% no-repeat padding-box;
      color: #D20F6E;
      width: 42px;
      height: 37px;
      pointer-events: none;
    }
  }
  .purple-color-square {
    padding-top: 10px;
    padding-right: 10px;
  }
  .hr-line {
    border-top: 1px solid #B4B4B4;
  }

  .question-marks {
    display: block;

    .question-mark {
      display: inline-block;
      margin-right: 5px;
    }

    .euro-icon {
      font-weight: bold;
      font-size: 36px;
      display: inline-block;
    }
  }

  .document-status {
    background-color: white;
    border-radius: 10px;
    min-height: 477px;
  }

  .text-orange {
    color: #F26822;
  }

  .document-current-step-description {
    color: #707070;
  }

  .see-more {
    color: #d2116e !important;
    text-decoration: none;
    cursor: pointer;

    .arrow-icon {
      color: #d2116e !important;
    }
  }

  .see-more:hover {
    color: rgba(0, 0, 0, 0.54) !important;

    .arrow-icon {
      margin-right: 8px !important;
      margin-left: 0px !important;
      color: rgba(0, 0, 0, 0.54) !important;
    }
  }

  .refund-title {
    font-size: 14px;
    color: #707070;
  }

  .refund-value {
    font-weight: bold;
  }

  .extension-square {
    color: white !important;
    height: 48px;
    width: 48px;
    background-color: #D20F6E;
    border-radius: 10px !important;
    text-align: center;
    font-weight: bold;

    span {
      margin-top: 14px;
      margin-left: 14px;
      font-size: 12px;
    }
  }
</style>
