<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable :max-width="maxWidth">
      <!-- WORKING HOURS -->
      <v-card class="orange-gradient-bg" v-if="isWorkingTime && !successScreen">
        <v-card-title class="small-title">
          <div class="container text-right close-button">
            <v-menu
                bottom
                right
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="close-menu"
                  @click="close()"
                >
                  <v-icon class="close-icon">mdi-close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </div>
        </v-card-title>
        <v-card-text v-if="!currentClientObject" class="dont-break text-center">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
        <v-card-text v-if="currentClientObject" class="dont-break text-center">
          <v-container class="mt-4">
            <v-row>
              <v-col cols="12" lg="6" class="mt-1 mt-lg-0 white-text pt-1 pt-md-16">
                <div class="user-icon-white-big mb-4"></div>
                <h2 class="semibold-font dont-break message-title">{{ $t('sign_up_now') }}</h2>
                <p class="semibold-font dont-break mt-2 mb-0 mb-md-4">{{ $t('get_the_maximum_return') }}</p>
                <v-col class="semibold-font mt-0 mt-lg-4">
                  <div class="d-none d-md-inline">
                    <v-icon class="phone-icon-orange">mdi-phone</v-icon>
                    {{ $t('call_us') + ': ' + companyPhone }}
                  </div>
                  <a :href="'tel:' + companyPhone" @click="saveCallActivity">
                    <button class="main-button-white main-button-fixed d-xs-inline d-md-none">
                      <v-icon class="phone-icon">mdi-phone</v-icon>
                      {{ $t('call_us') }}
                    </button>
                  </a>
                </v-col>
              </v-col>
              <v-col cols="12" class="pt-0 d-lg-none">
                <div class="white-text">{{ $t('or') }}</div>
              </v-col>
              <v-col cols="12" lg="6" class="mt-1 mt-lg-0 white-bg pa-4">
                <h2 class="mt-4">{{ $t('approve_your_data') }}</h2>
                <v-col cols="12" class="mt-0 mt-md-4">
                  <v-text-field
                    v-model="currentClientObject.attributes.first_name"
                    :label="$t('firstName')"
                    :disabled='true'
                    class="outlined__input"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-0 mt-md-4">
                  <v-text-field
                    v-model="currentClientObject.attributes.last_name"
                    :label="$t('lastName')"
                    :disabled='true'
                    class="outlined__input"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-0 mt-md-4">
                  <v-text-field
                    v-model="currentClientObject.attributes.phone"
                    :label="$t('phone')"
                    class="outlined__input"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-0 mt-md-4">
                  <v-text-field
                    v-model="currentClientObject.attributes.email"
                    :label="$t('email')"
                    class="outlined__input"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col class="mt-0 mt-md-4">
                  <button class="main-button main-button-fixed" :disabled="loading" @click="acceptOffer()">
                    {{ $t('accept') }}
                    <v-progress-circular
                      v-if="loading"
                      :size="30"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </button>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <!-- AFTER HOURS -->
      <v-card v-if="!isWorkingTime && !successScreen">
        <v-card-title>
          <div class="container text-right">
            <v-menu
                bottom
                right
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="close-menu"
                  @click="close()"
                >
                  <v-icon class="close-icon">mdi-close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </div>
          <div class="container text-center">
            <div class="user-icon-big mb-4"></div>
            <h2 class="semibold-font dont-break message-title">{{ $t('sign_up_now') }}</h2>
            <p class="semibold-font dont-break mt-2">{{ $t('get_the_maximum_return') }}</p>
          </div>
        </v-card-title>
        <v-card-text v-if="!currentClientObject" class="dont-break text-center">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-card-text>
        <v-card-text v-if="currentClientObject" class="dont-break text-center">
          <div>{{ $t('approve_your_data') }}</div>

          <v-container class="mt-4">
              <v-row>
                <v-col cols="12" md="6" class="mt-1 mt-lg-0">
                  <v-text-field
                    v-model="currentClientObject.attributes.first_name"
                    :label="$t('firstName')"
                    :disabled='true'
                    class="outlined__input"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6"  class="mt-1 mt-lg-0">
                  <v-text-field
                    v-model="currentClientObject.attributes.last_name"
                    :label="$t('lastName')"
                    :disabled='true'
                    class="outlined__input"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4"  class="mt-1 mt-lg-0">
                  <v-text-field
                    v-model="currentClientObject.attributes.phone"
                    :label="$t('phone')"
                    class="outlined__input"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="8"  class="mt-1 mt-lg-0">
                  <v-text-field
                    v-model="currentClientObject.attributes.email"
                    :label="$t('email')"
                    class="outlined__input"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="12" class="text-center">
            <button class="main-button main-button-fixed" :disabled="loading" @click="acceptOffer()">
              {{ $t('accept') }}
              <v-progress-circular
                v-if="loading"
                :size="30"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </button>
          </v-col>
        </v-card-actions>
      </v-card>
      <!-- SUCCESS SCREEN -->
      <v-card v-if="successScreen">
        <v-card-title>
          <div class="container text-right">
            <v-menu
                bottom
                right
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="close-menu"
                  @click="close()"
                >
                  <v-icon class="close-icon">mdi-close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </div>
          <div class="container text-center">
            <div class="consultant-icon-big mb-4"></div>
            <h2 class="semibold-font dont-break message-title">{{ $t('thanks') }}</h2>
          </div>
        </v-card-title>
        <v-card-text class="dont-break text-center pl-8 pr-8">
          <div>{{ $t('we_received_your_request') }}</div>
          <v-row class="mt-4 annotation pl-16 pr-16">
            <v-col cols="1" class="mt-2">
              <span><img src='@/assets/icons/phone-icon.svg' /></span>
            </v-col>
            <v-col cols="11 grey-text">
              <span>{{ $t('contact_from_us') }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="12" class="text-center">
            <router-link :to="'/documents'">
              <button class="main-button main-button-fixed">
                {{ $t('back_to_panel') }}
              </button>
            </router-link>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from 'moment-timezone'
import { mapGetters } from 'vuex'

export default {
  name: 'showAcceptOfferModal',
  data () {
    return {
      dialog: true,
      successScreen: false,
      loading: false,
      currentClientObject: null
    }
  },
  created () {
    this.$http.get(`client_accounts/${this.currentClient.id}/client_details`)
      .then(response => {
        this.currentClientObject = response.data
      })
  },
  computed: {
    ...mapGetters({ currentClient: 'currentClient' }),
    content () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return ''
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return ''
      } else if (location.host.includes('.euro-tax.pl')) {
        return 'Wyrażam zgodę w celu umożliwienia uzyskiwania przez Euro-Tax.pl Zwrot Podatku S.A. informacji od zagranicznych urzędów o uzyskanych dochodach i przedstawienia mi bezpłatnych analiz w ramach propozycji rozliczeń podatku za 2022 rok.'
      } else {
        return 'Wyrażam zgodę w celu umożliwienia uzyskiwania przez CUF sp. z o.o. informacji od zagranicznych urzędów o uzyskanych dochodach i przedstawienia mi bezpłatnych analiz w ramach propozycji rozliczeń podatku za 2022 rok.'
      }
    },
    companyPhone () {
      if (this.$i18n.locale === 'ro' || location.host.includes('.ro')) {
        return '0040317803196'
      } else if (this.$i18n.locale === 'bg' || location.host.includes('.bg')) {
        return '+359 884 633 219'
      } else if (location.host.includes('.euro-tax.pl')) {
        return '+48 729 781 857'
      } else {
        return '+48 729 781 857'
      }
    },
    maxWidth () {
      if (this.isWorkingTime) {
        return '1000px !important'
      } else {
        return '700px'
      }
    },
    isWorkingTime () {
      return this.isWorkingHours && this.isWorkingDays
    },
    isWorkingHours () {
      const currentDate = moment().tz('Europe/Warsaw')
      const hoursFormat = 'hh:mm:ss'
      let startTime = null
      let endTime = null

      if (location.host.includes('.ro')) {
        startTime = moment('08:00:00', hoursFormat)
        endTime = moment('16:30:00', hoursFormat)
      } else if (location.host.includes('.bg')) {
        startTime = moment('08:00:00', hoursFormat)
        endTime = moment('16:00:00', hoursFormat)
      } else if (location.host.includes('.euro-tax.pl')) {
        startTime = moment('09:00:00', hoursFormat)
        endTime = moment('16:00:00', hoursFormat)
      } else {
        startTime = moment('01:00:00', hoursFormat)
        endTime = moment('01:00:01', hoursFormat)
      }

      return moment(currentDate, hoursFormat).isBetween(startTime, endTime)
    },

    isWorkingDays () {
      const currentDate = moment().tz('Europe/Warsaw')
      const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

      return days.includes(currentDate.format('dddd'))
    }
  },
  methods: {
    acceptOffer () {
      this.loading = true
      this.$http.put(`documents/${this.$route.params.documentId}/accept_offer`, { data: { email: this.currentClientObject.attributes.email, phone: this.currentClientObject.attributes.phone } })
        .then(response => {
          this.successScreen = true
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    saveCallActivity () {
      this.$http.post(`documents/${this.$route.params.documentId}/save_activity`, { data: { activity: 'press_call_button' } })
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
  .v-dialog {
    max-width: 700px !important;
  }

  .user-icon-big {
    background: transparent url('../../../assets/icons/user-icon.svg') 0% 0% no-repeat padding-box;
    display: inline-block;
    width: 87px;
    height: 78px;
    background-size: 87px 78px;

    @media only screen and (max-width: 400px) {
      background-size: 66px 78px;
    }

    i {
      margin-top: 30%;
      color: white;
      font-size: 64px;
    }
  }
  .user-icon-white-big {
    background: transparent url('../../../assets/icons/user-icon-white.svg') 0% 0% no-repeat padding-box;
    display: inline-block;
    width: 87px;
    height: 78px;
    background-size: 87px 78px;

    i {
      margin-top: 30%;
      color: white;
      font-size: 64px;
    }
  }
  .consultant-icon-big {
    background: transparent url('../../../assets/icons/consultant.svg') 0% 0% no-repeat padding-box;
    display: inline-block;
    width: 181px;
    height: 154px;
    background-size: 181px 154px;

    i {
      margin-top: 30%;
      color: white;
      font-size: 64px;
    }
  }

  .annotation {
    font-size: 11px;
    color: #B4B4B4;
  }

  .orange-gradient-bg {
    background: transparent linear-gradient(270deg, #F57921 0%, #F5B521 100%) 0% 0% no-repeat padding-box;
  }

  .white-bg {
    background: white;
    border-radius: 10px;
  }

  .white-text {
    color: white !important;
  }

  .phone-icon {
    color: #F57921 !important;
  }

  .phone-icon-orange {
    color: #F57921 !important;
  }

  .phone-icon:hover {
    display: none !important;
  }

  .main-button-white {
    @media screen and (max-width: 1263px) {
      margin-top: 10px;
    }
    @media screen and (min-width: 1264px) {
      margin-top: 230px;
    }
    &:hover {
      .phone-icon {
        display: none !important;
      }
    }
  }

  .small-title {
    height: 10px;

    .close-button {
      @media screen and (max-width: 1263px) {
        margin-top: -10px;
      }
      @media screen and (min-width: 1264px) {
        margin-top: -20px;
      }
    }

    .close-icon {
      color: white !important;
    }
  }
</style>
