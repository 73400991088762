<template>
  <div class="container">
    <div v-if="!documentsLoading">
      <div class="row container">
        <router-link :to="'/documents'">
          <button class="back-icon mr-4"></button>
        </router-link>
        <h2 class="pt-2 semibold-font">{{ $t('case_details') }}</h2>
      </div>
      <div class="row container d-none d-lg-flex">
        <h4 class="year_h4">{{document.attributes['year'] }}</h4>
        <h4 class="product_h4 ml-4">{{document.attributes['product'] }}</h4>
        <div class="symbol_div ml-4">{{document.attributes['symbol'] }}</div>
      </div>
      <div class="row d-flex d-lg-none mt-0 pt-0 pb-0">
        <v-col cols="9">
          <h4 class="year_h4">
            <span>{{document.attributes['year'] }}</span>
            <span class="product_h4 ml-4">{{document.attributes['product'] }}</span>
          </h4>
        </v-col>
        <v-col cols="3" class="text-right">
          <div class="symbol_div">{{document.attributes['symbol'] }}</div>
        </v-col>
      </div>
      <div class="row pa-2 pr-2">
        <v-col cols="12" lg="5" class="document-status pa-6">
          <div v-if="document.attributes['status'] &&  document.attributes.current_step">
            <h1 class="text-orange mb-4 semibold-font">{{ document.attributes['status'] }}</h1>
            <h5 class="mb-2 semibold-font">{{ $t('settlement_stage_uppercase') }}:</h5>
            <div>{{ document.attributes.current_step['step_name'] }}</div>
            <div class="document-current-step-description mt-8">{{ document.attributes.current_step['step_description'] }}</div>
          </div>
          <div class="see-more mt-6 text-center text-lg-left">
            <router-link class="see-more" :to="'/documents/' + document.id + '/history'">
              {{ $t('case_history') }}
              <v-icon
                small
                class="ml-2 arrow-icon"
              >
                fas fa-arrow-right
              </v-icon>
            </router-link>
          </div>
        </v-col>
        <v-col cols="12" lg="5" class="document-status pa-6" v-if="displayNeededInformation">
          <v-card class="need-information__container mx-auto pa-6">
            <h3 class="mb-2 gradient-pink pt-4 pl-10 pb-4 semibold-font">{{ $t('neededInformation') }}:</h3>
            <div class="description-text mb-1">{{ $t('neededInformationDescription') }}</div>
            <h4 class="mb-2 mt-6 mb-4 semibold-font">{{ $t('applicationForm') }}:</h4>
            <div>
              <div class="description-text mb-1">{{ $t('personalData') }}</div>
              <div class="see-more mt-2 mb-4 text-right" @click="moveToEditDocumentForm('create_document')">
                <button class="see-more">
                  <v-icon
                    small
                    class="ml-2 arrow-icon"
                  >
                    fas fa-edit
                  </v-icon>
                </button>
                {{ $t('edit') }}
              </div>
            </div>
            <div>
              <div class="description-text mb-1">{{ $t('familyData') }}</div>
              <div class="see-more mt-2 mb-4 text-right" @click="moveToEditDocumentForm('identify_number')">
                <button class="see-more">
                  <v-icon
                    small
                    class="ml-2 arrow-icon"
                  >
                    fas fa-edit
                  </v-icon>
                </button>
                {{ $t('edit') }}
              </div>
            </div>
            <div>
              <div class="description-text mb-1">{{ $t('globalIncomeData') }}</div>
              <div class="see-more mt-2 mb-4 text-right" @click="moveToEditDocumentForm('children_summary')">
              <button class="see-more">
                <v-icon
                  small
                  class="ml-2 arrow-icon"
                >
                  fas fa-edit
                </v-icon>
              </button>
                {{ $t('edit') }}
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6"  class="pa-0 pa-lg-6ml-0 ml-lg-4">
          <div class="row mt-6 mb-12">
            <v-col cols="6" lg="7">
              <div class="mb-4" v-for="(estimatedRefundAmount) in notEmptyestimatedRefundAmounts" :key="estimatedRefundAmount['title']">
                <div class="refund-title">{{ estimatedRefundAmount['title'] }}:</div>
                <div class="refund-value">{{ (estimatedRefundAmount['amount']) }} {{ estimatedRefundAmount['currency'] }}</div>
              </div>
            </v-col>
            <v-col cols="6" lg="5">
              <div v-if="document.attributes['average_waiting_time_for_issuing_decision']" class="mb-4">
                <div class="refund-title">{{ $t('average_waiting_time') }}:</div>
                <div class="refund-value">{{ document.attributes['average_waiting_time_for_issuing_decision'] }}</div>
              </div>
              <div v-if="document.attributes['average_waiting_time_for_issuing_decision_church_tax']" class="mb-4">
                <div class="refund-title">{{ $t('church_tax') }}:</div>
                <div class="refund-value">{{ document.attributes['average_waiting_time_for_issuing_decision_church_tax'] }}</div>
              </div>
              <div v-if="document.attributes['average_waiting_time_for_issuing_decision_more_time']" class="mb-4">
                <div class="refund-title">{{ $t('average_waiting_time_for_issuing_decision_more_time') }}:</div>
                <div class="refund-value">
                  {{ document.attributes['average_waiting_time_for_issuing_decision_more_time'] }}
                  <router-link class="see-more" :to="'/documents/' + document.id + '/history'">
                    {{ $t('case_history') }}
                    <v-icon
                      small
                      class="ml-2 arrow-icon"
                    >
                      fas fa-arrow-right
                    </v-icon>
                  </router-link>
                </div>
              </div>
            </v-col>
            <v-col cols="12 text-center text-lg-left">
              <div v-if="document.attributes['display_attachments']">
                <strong>{{ $t('document_attachments') }}:
                  <router-link class="see-more ml-2" :to="'/documents/' + document.id + '/attachments'">
                    {{ document.attributes.attachment_size }}
                    <v-icon
                      small
                      class="ml-2 arrow-icon"
                    >
                      fas fa-arrow-right
                    </v-icon>
                  </router-link>
                </strong>
              </div>
            </v-col>
            <v-col cols="12 text-center text-lg-left mb-12 mb-lg-9" v-if="canUpdateAccountNumberModal">
              <div class="see-more mt-6">
                <a class="see-more" @click="displayModal">
                  {{ $t('updateAccountNumber') }}
                  <v-icon
                    small
                    class="ml-2 arrow-icon"
                  >
                    fas fa-arrow-right
                  </v-icon>
                </a>
                <updateAccountNumberModal v-if="showUpdateAccountNumberModal" @close="showUpdateAccountNumberModal = false" :updateAccountPermissions="updateAccountPermissions"></updateAccountNumberModal>
              </div>
            </v-col>
          </div>
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import updateAccountNumberModal from '@/components/updateAccountNumberModal'

export default {
  name: 'showDocument',
  data () {
    return {
      showUpdateAccountNumberModal: false,
      showUpdateContactDetailsModal: false,
      updateAccountPermissions: {}
    }
  },
  computed: {
    ...mapGetters(['document']),
    ...mapGetters(['documentsLoading']),
    canUpdateAccountNumberModal () {
      return !this.updateAccountPermissions.closed && this.updateAccountPermissions.can_change
    },

    notEmptyestimatedRefundAmounts () {
      return this.document.attributes.estimated_refund_amounts && this.document.attributes.estimated_refund_amounts.filter(estimatedRefundAmount => !!estimatedRefundAmount.amount)
    },

    displayNeededInformation () {
      return this.document.attributes.client_can_edit === 'full' || this.document.attributes.client_can_edit === 'limited'
    }
  },
  components: {
    updateAccountNumberModal
  },
  created () {
    this.$store.dispatch('getDocument', this.$route.params.documentId)
    this.$http.get(`documents/${this.$route.params.documentId}/update_account_permissions`)
      .then(request => {
        this.updateAccountPermissions = request.data
      })
  },
  methods: {
    displayModal () {
      this.showUpdateAccountNumberModal = true
    },
    displayUpdateContactDetailsModal () {
      this.showUpdateContactDetailsModal = true
    },

    moveToEditDocumentForm (form) {
      this.$router.push(`/documents/${this.$route.params.documentId}/edit?state=${form}`)
    }
  }
}
</script>

<style lang="scss">
  .document-status {
    background-color: white;
    border-radius: 10px;
    min-height: 477px;
  }

  .text-orange {
    color: #F26822;
  }

  .document-current-step-description {
    color: #707070;
  }

  .see-more {
    color: #d2116e !important;
    text-decoration: none;

    .arrow-icon {
      color: #d2116e !important;
    }
  }

  .see-more:hover {
    color: rgba(0, 0, 0, 0.54) !important;

    .arrow-icon {
      margin-right: 8px !important;
      margin-left: 0px !important;
      color: rgba(0, 0, 0, 0.54) !important;
    }
  }

  .refund-title {
    font-size: 14px;
    color: #707070;
  }

  .refund-value {
    font-weight: bold;
  }

  .need-information__container {
    width: 574px;
    border-radius: 20px !important;
    opacity: 1;
    background: linear-gradient(white, white) padding-box,
              linear-gradient(to right, #F57921, #D20F6E) border-box !important;
    border: 2px solid transparent !important;
  }

  .description-text {
    font-size: 14px;
    line-height: 1.55;
  }
</style>
