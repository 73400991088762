<template>
  <div>
    <div class="container">
      <v-progress-circular
        v-if="documentsLoading"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <documentView v-if="document && document.attributes.special_offer_variant == null"></documentView>
    <orderOfferView v-if="document && document.attributes.special_offer_variant == 1"></orderOfferView>
    <acceptBasicOffer v-if="document && document.attributes.special_offer_variant == 2"></acceptBasicOffer>
    <acceptExtendedOffer v-if="document && document.attributes.special_offer_variant == 3"></acceptExtendedOffer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import documentView from '@/components/document/general/document'
import orderOfferView from '@/components/document/general/orderOffer'
import acceptBasicOffer from '@/components/document/general/acceptBasicOffer'
import acceptExtendedOffer from '@/components/document/general/acceptExtendedOffer'

export default {
  name: 'showDocument',
  computed: {
    ...mapGetters(['document']),
    ...mapGetters(['documentsLoading'])
  },
  components: {
    documentView,
    orderOfferView,
    acceptBasicOffer,
    acceptExtendedOffer
  },
  created () {
    this.$store.dispatch('getDocument', this.$route.params.documentId)
  }
}
</script>

<style lang="scss">
</style>
