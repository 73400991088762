<template>
  <div class="container">
    <div v-if="!documentsLoading">
      <div class="row container">
        <h2 class="pt-2 semibold-font">{{ $t('your_settlement') }}</h2>
      </div>
      <div class="row container d-none d-lg-flex">
        <h4 class="year_h4">{{document.attributes['year'] }}</h4>
        <h4 class="product_h4 ml-4">{{document.attributes['product'] }}</h4>
      </div>
      <div class="row d-flex d-lg-none mt-0 pt-0 pb-0">
        <v-col cols="9">
          <h4 class="year_h4">
            <span>{{ document.attributes['year'] }}</span>
            <span class="product_h4 ml-4">{{document.attributes['product'] }}</span>
          </h4>
        </v-col>
      </div>
      <div class="row pa-2 pr-2">
        <v-col cols="12" lg="5">
          <div class="document-status pa-6">
            <div class="document-current-step-description mt-8">
              <h4 class="mb-4">{{ $t('accept_basic_offer_text_header') }}</h4>
              <p v-if="document.attributes['product'].includes('Niemcy')">{{ $t('accept_basic_offer_text1_de', { year: document.attributes['year'] }) }}
              <p v-if="document.attributes['product'].includes('Holandia')">{{ $t('accept_basic_offer_text1_nl', { year: document.attributes['year'] }) }}
              <span style="color: #f26822;font-weight: bold;">{{ $t('accept_basic_offer_text1_color') }}</span></p>
              <p>
                {{ $t('accept_basic_offer_text2') }}
                <span style="color: #f26822;font-weight: bold;">{{ document.attributes.special_offer_amount }} euro</span>
                <span>{{ $t('accept_basic_offer_text2_end')}}</span>
              </p>
              <p>{{ $t('accept_basic_offer_text3') }}</p>
            </div>
          </div>

          <div cols="12" lg="5" class="orange-radius-border mt-6 pa-6">
            <div class="row mt-3">
              <v-col cols="2">
                <button class="clock-icon ml-2 mr-4"></button>
              </v-col>
              <v-col cols="10">
                <h4 class="mb-4">{{ $t('no_wait') }}</h4>
                <p>{{ $t('want_to_highest_return') }}</p>
                <div class="mt-6">
                  <div class="accept-offer" @click="showContactModal()">
                    {{ $t('sign_up_now') }}
                    <v-icon
                      small
                      class="ml-2 arrow-icon"
                    >
                      fas fa-arrow-right
                    </v-icon>
                  </div>
                </div>
              </v-col>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="6"  class="pa-0 pa-lg-6ml-0 ml-lg-4">
          <div class="row mt-6 ml-lg-4">
            <v-col cols="12" lg="5" class="refund-value-border">
              <div class="mb-4">
                <div class="pt-2 semibold-font" v-html="$t('you_can_recover2')"></div>
                <div class="euro-icon mt-2">{{ document.attributes.special_offer_amount }} € </div>
                <div>{{ $t('tax_return') }}</div>
              </div>
            </v-col>
            <v-col cols="12" lg="6">
              <div class="mt-4 square-description">
                <div>{{ $t('maximum_amount') }}</div>
                <div class="mt-2">{{ $t('it_was_calculated') }}</div>
              </div>
            </v-col>
            <v-col cols="12">
              <hr class="hr-line mt-8 mb-2">
            </v-col>
            <v-col cols="12 text-center text-lg-left margin-bottom-mobile-160">
              <div style="font-weight: bold;">{{ $t('more_informations') }}</div>
              <v-simple-table v-if="document && document.attributes.special_offer_attachment" class="mt-6 documents-table">
                <tbody>
                  <tr class="pointer-mouse" @click="openAttachment(document.attributes.special_offer_attachment)">
                    <td width="5%">
                      <div class="extension-square row">
                        <span>{{ document.attributes.special_offer_attachment.extension }}</span>
                      </div>
                    </td>
                    <td width="30%">
                      <div class="table-filename semibold-font">
                        {{ document.attributes.special_offer_attachment.filename }}
                      </div>
                      <div class="table-date" style="color: #707070">
                        {{ $date(document.attributes.special_offer_attachment['created_at']).format('DD.MM.YYYY') }}
                      </div>
                    </td>
                    <td width="10%" class="see-more">
                      <a class="see-more" v-bind:href="document.attributes.special_offer_attachment.url" target="_blank">
                        {{ $t('download') }}
                        <v-icon
                          small
                          class="ml-2 arrow-icon"
                        >
                          fas fa-arrow-right
                        </v-icon>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </div>
        </v-col>
      </div>
    </div>
    <contactModal v-if="displayContactModal" @close="displayContactModal = false"></contactModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import contactModal from '@/components/document/general/contactModal'

export default {
  name: 'orderOffer',
  data () {
    return {
      displayContactModal: false
    }
  },
  computed: {
    ...mapGetters(['document']),
    ...mapGetters(['documentsLoading'])
  },
  components: {
    contactModal
  },
  created () {
    this.$store.dispatch('getDocument', this.$route.params.documentId)
  },
  methods: {
    showContactModal () {
      this.displayContactModal = true
    },
    openAttachment (attachment) {
      window.open(attachment.url, '_blank')
    }
  }
}
</script>

<style lang="scss">
  .documents-table {
    background-color: transparent !important;

    tr:hover {
      background-color: transparent !important;
    }
  }

  .refund-value-border {
    background: transparent linear-gradient(137deg, #F57921 0%, #D20F6E 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    color: white !important;
  }

  .orange-radius-border {
    background: transparent linear-gradient(270deg, #F57921 0%, #F5B521 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    color: white !important;

    .clock-icon {
      background: transparent url('../../../assets/icons/clock-icon.svg') 0% 0% no-repeat padding-box;
      color: #D20F6E;
      width: 38px;
      height: 44px;
    }

    .arrow-icon {
      color: #534a3b !important;
    }

    .accept-offer {
      color: #534a3b !important;
      text-decoration: none;
      cursor: pointer;

      .arrow-icon {
        color: #534a3b !important;
      }
    }

    .accept-offer:hover {
      color: rgba(0, 0, 0, 0.54) !important;

      .arrow-icon {
        margin-right: 8px !important;
        margin-left: 0px !important;
        color: rgba(0, 0, 0, 0.54) !important;
      }
    }
  }

  .hr-line {
    border-top: 1px solid #B4B4B4;
  }

  .euro-icon {
    font-weight: bold;
    font-size: 36px;
  }

  .document-status {
    background-color: white;
    border-radius: 10px;
    min-height: inherit !important;
  }

  .text-orange {
    color: #F26822;
  }

  .document-current-step-description {
    color: #707070;
  }

  .see-more {
    color: #d2116e !important;
    text-decoration: none;
    cursor: pointer;

    .arrow-icon {
      color: #d2116e !important;
    }
  }

  .see-more:hover {
    color: rgba(0, 0, 0, 0.54) !important;

    .arrow-icon {
      margin-right: 8px !important;
      margin-left: 0px !important;
      color: rgba(0, 0, 0, 0.54) !important;
    }
  }

  .refund-title {
    font-size: 14px;
    color: #707070;
  }

  .refund-value {
    font-weight: bold;
  }

  .extension-square {
    color: white !important;
    height: 48px;
    width: 48px;
    background-color: #D20F6E;
    border-radius: 10px !important;
    text-align: center;
    font-weight: bold;

    span {
      margin-top: 14px;
      margin-left: 14px;
      font-size: 12px;
    }
  }
</style>
